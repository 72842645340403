import React, { useState, useEffect } from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import SiteEditDialog from './Dialogs/SiteEditDialog';
import { useCommonConfigFunctions } from '../../hooks/useCommonConfigFunctions';
import DataTable from '../DataTable/datatable.component';

function SitesTable({ onRowClick, refresh }) {

    const [data, setData] = useState();
    const { getAllFunction: getSites, loading } = useCommonConfigFunctions("sites");

    useEffect(() => {
        getSites().then(sites => setData(sites));
    }, [refresh])

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'stringIncludes',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original) }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Site Code',
            accessor: 'siteCode',
            filter: 'stringIncludes'
        },
        {
            Header: 'AD Group Name',
            accessor: 'adGroupName',
            filter: 'stringIncludes'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return data ? <DataTable id="Sites" tableColumns={tableColumns} tableData={data} /> : <p>No data</p>
}

function SiteConfiguration() {
    const getNewSite = () => {
        return { id: null, siteCode: "", name: "", adGroupName: "" }
    };

    return (
        <CommonConfigPage
            title="Sites"
            table={(showEditDialog, refreshList) => <SitesTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <SiteEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewSite}
        />
    );
}

export default SiteConfiguration;