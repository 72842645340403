import React, { useState, useEffect } from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useCommonConfigFunctions } from '../../hooks/useCommonConfigFunctions';
import DataTable from '../DataTable/datatable.component';
import ConfigurationDataPropertyEditDialog from './Dialogs/ConfigurationDataPropertyEditDialog';

function ConfigurationDataPropertiesTable({ onRowClick, refresh }) {

    const [data, setData] = useState();
    const { getAllFunction: getConfigurationDataProperties, loading } = useCommonConfigFunctions("configurationDataProperties");

    useEffect(() => {
        getConfigurationDataProperties().then(configDataProperties => setData(configDataProperties));
    }, [refresh])

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'stringIncludes',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original) }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Description',
            accessor: 'description',
            filter: 'stringIncludes'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return data ? <DataTable id="ConfigurationDataProperties" tableColumns={tableColumns} tableData={data} /> : <p>No data</p>
}

function ConfigurationDataProperties() {
    const getNewConfigurationDataProperty = () => {
        return { id: null, name: "", description: "" }
    };

    return (
        <CommonConfigPage
            title="Configuration Data"
            table={(showEditDialog, refreshList) => <ConfigurationDataPropertiesTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <ConfigurationDataPropertyEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewConfigurationDataProperty}
        />
    );
}

export default ConfigurationDataProperties;