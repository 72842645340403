import React, { useState, useEffect } from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useCommonConfigFunctions } from '../../hooks/useCommonConfigFunctions';
import DataTable from '../DataTable/datatable.component';
import SelectFilter from '../DataTable/datatable.selectfilter';
import ConfigurationDataEditDialog from './Dialogs/ConfigurationDataEditDialog';

function ConfigurationDataTable({ onRowClick, refresh }) {

    const [data, setData] = useState();
    const { getAllFunction: getConfigurationData, loading } = useCommonConfigFunctions("configurationData");

    useEffect(() => {
        getConfigurationData().then(configData => setData(configData));
    }, [refresh])

    const tableColumns = [
        {
            Header: 'Property',
            accessor: 'configurationDataProperty.name',
            Filter: SelectFilter,
            filter: 'equals',
            Cell: (props) => {
                return <Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>
            }
        },
        {
            Header: 'Value',
            accessor: 'value',
            filter: 'fuzzyText'
        },
        {
            Header: 'Site',
            accessor: 'site.name',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return data ? <DataTable id="ConfigurationData" tableColumns={tableColumns} tableData={data} /> : <p>No data</p>
}

function ConfigurationData() {
    const getNewConfigurationData = () => {
        return { id: null, ConfigurationDataPropertyId: null, value: "", siteId: null }
    };

    return (
        <CommonConfigPage
            title="Configuration Data"
            table={(showEditDialog, refreshList) => <ConfigurationDataTable onRowClick={showEditDialog} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <ConfigurationDataEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewConfigurationData}
        />
    );
}

export default ConfigurationData;