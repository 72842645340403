import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import DataTable from './DataTable/datatable.component';
import NoFilter from './DataTable/datatable.nofilter';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import useAlert from './Alerts/useAlert';

function JobHistory() {
    return (
        <Container>
            <h3>Job History</h3>
            <JobHistoryTable />
        </Container>
    );
}

function JobHistoryTable() {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState();
    const [jobHistory, setJobHistory] = useState([]);

    useEffect(() => {
        getJobHistory();
    }, [])

    function getJobHistory() {

        fetchDataAuthenticated("api/jobHistory")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to get job history');
            })
            .then(data => {
                setJobHistory(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    const tableColumns = [
        {
            Header: 'Job ID',
            accessor: 'jobId',
            filter: 'includesString'
        },
        {
            Header: 'Valve',
            accessor: 'valveName',
            filter: 'includesString'
        },
        {
            Header: 'Property Name',
            accessor: 'propertyName',
            filter: 'includesString'
        },
        {
            Header: 'Old Value',
            accessor: 'oldValue',
            filter: 'includesString'
        },
        {
            Header: 'New Value',
            accessor: 'newValue',
            filter: 'includesString'
        },
        {
            Header: 'Date Changed UTC',
            accessor: 'dateChangedUTC',
            Filter: NoFilter
        },
        {
            Header: 'Account',
            accessor: 'account',
            filter: 'includesString'
        }
    ];

    const initialState = {
        pageSize: 25
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return jobHistory.length > 0
        ?
        <DataTable id="JobHistory" tableColumns={tableColumns} tableData={jobHistory} initialState={initialState} />
        :
        <div className="d-flex flex-column mt-5">
            <h4 className="text-center">No job history to show</h4>
        </div>
}

export default JobHistory;