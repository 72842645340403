import React, { useState, useEffect } from 'react';
import { useListPrivileges } from '../hooks/usePrivileges';
import { Link } from 'react-router-dom';
import CorporateDlg from './Dialogs/FooterDialogs/CorporateDlg';
import SupportDlg from './Dialogs/FooterDialogs/SupportDlg';
import AboutDlg from './Dialogs/FooterDialogs/AboutDlg';
import { Container, Row, Col } from 'react-bootstrap';
import { useMsal } from '@azure/msal-react';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';


function Footer() {
    const { privileges } = useListPrivileges();

    const [version, setVersion] = useState();
    const [copyright, setCopyright] = useState();

    const { accounts } = useMsal();
    const username = accounts[0] && accounts[0].name;

    useEffect(() => {
        getAppData();
    }, [accounts]);

    const getAppData = async function () {
        await fetchDataAuthenticated(`api/app`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setVersion(data.version);
                setCopyright(data.copyrightInfo);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const [corporateDlgIsOpen, setCorporateDlgIsOpen] = useState(false);
    const [supportDlgIsOpen, setSupportDlgIsOpen] = useState(false);
    const [aboutDlgIsOpen, setAboutDlgIsOpen] = useState(false);

    const toggleCorporateDlg = () => {
        setCorporateDlgIsOpen(!corporateDlgIsOpen);
    };

    const toggleSupportDlg = () => {
        setSupportDlgIsOpen(!supportDlgIsOpen);
    };

    const toggleAboutDlg = () => {
        setAboutDlgIsOpen(!aboutDlgIsOpen);
    };

    return (
        <>
            <Container fluid className="footer border-top">
                <Row>
                    <Col className="d-flex">
                        <Link className="link me-2" to='#' onClick={toggleAboutDlg}>About</Link>
                        <Link className="link me-3" to='#' onClick={toggleSupportDlg}>Support</Link>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-end">
                            <Link className="link me-2" to='#' onClick={toggleCorporateDlg}>Corporate Separateness Notice</Link>
                            <p className="text-dark d-none d-md-inline-block">{copyright}</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <CorporateDlg isOpen={corporateDlgIsOpen} toggleModal={toggleCorporateDlg} />
            <SupportDlg isOpen={supportDlgIsOpen} toggleModal={toggleSupportDlg} />
            <AboutDlg isOpen={aboutDlgIsOpen} toggleModal={toggleAboutDlg} userName={username}
                privileges={privileges} version={version} copywrite={copyright} />
        </>
    )
}

export default Footer;