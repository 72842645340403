import React, { useState } from 'react';
import { RequiredDialogInput, DialogInput, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useCommonConfigFunctions } from '../../../hooks/useCommonConfigFunctions';

function AreaEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const [fieldErrors, setFieldErrors] = useState({});

    const { createFunction, updateFunction, deleteFunction } = useCommonConfigFunctions("areas", "area");

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    const createOrUpdate = (object) => {
        return object.id ? updateFunction(object) : createFunction(object);
    }

    return (
        <CommonConfigDialog title={"Area - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={createOrUpdate} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} canDelete={true} deleteFunction={deleteFunction}>
            <RequiredDialogInput controlId="formAreaName" title="Name" placeholder="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogInput controlId="formAreaADGroupName" title="AD Group Name" placeholder="AD Group Name" name="adGroupName" value={dialogData.adGroupName} error={fieldErrors.adGroupName} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default AreaEditDialog;