import React from 'react';
import { Navigate, Route } from 'react-router';
import { Routes } from 'react-router-dom';
import NavMenu from './components/NavMenu';
import Jobs from './components/Jobs';
import ActiveTasks from './components/ActiveTasks';
import JobHistory from './components/JobHistory';
import Notifier from './services/NotifierService';
import NotifierContext from './components/Contexts/NotificationContext'
import { AlertProvider } from './components/Alerts/AlertContext';
import NotificationAlert from './components/Alerts/NotificationAlert';
import { SiteProvider } from './components/Contexts/SiteContext';
import Configuration from './components/Configuration';
import Footer from './components/Footer';

import './custom.css';



function App() {

    return (
        <>
            <SiteProvider>
                <AlertProvider>
                    <NotificationAlert />
                    <NotifierContext.Provider value={Notifier()}>
                        <NavMenu />
                        <Routes>
                            <Route path='*' element={<Navigate to='/jobs' />} />
                            <Route path='/jobs' exact element={<Jobs />} />
                            <Route path='/active-tasks' exact element={<ActiveTasks />} />
                            <Route path='/job-history' exact element={<JobHistory />} />
                            <Route path='/config' exact element={<Configuration />} />
                        </Routes>
                        <Footer />
                    </NotifierContext.Provider>
                </AlertProvider>
            </SiteProvider>
        </>
    );
}

export default App;
