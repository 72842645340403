import { useState } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';

export const useCommonConfigFunctions = (resource, entityNameSingular) => {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);
    const url = `api/${resource}`;

    const getAllFunction = async () => {
        let items = [];

        await fetchDataAuthenticated(url, "GET")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                items = data;
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })

        return items;
    }

    const getFunction = async (id) => {
        let item;

        await fetchDataAuthenticated(`${url}/${id}`, "GET")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                item = data;
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })

        return item;
    }

    const createFunction = async (object) => {
        await fetchDataAuthenticated(url, "POST", JSON.stringify(object))
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Success", `Created ${entityNameSingular} ${object.name ?? object.id}`);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const updateFunction = async (object) => {
        await fetchDataAuthenticated(url, "PUT", JSON.stringify(object))
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Success", `Updated ${entityNameSingular} ${object.name ?? object.id}`);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const deleteFunction = async (object) => {
        await fetchDataAuthenticated(`${url}/${object.id}`, "DELETE")
            .then(async response => {
                if (response.ok) {
                    return;
                }
                throw new Error(await response.text());
            })
            .then(() => {
                setAlert("success", "Success", `Deleted ${entityNameSingular} ${object.name ?? object.id}`);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { getAllFunction, getFunction, createFunction, updateFunction, deleteFunction, loading };
}
