import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, Button } from 'react-bootstrap';
import JobsTable from './JobsTable';
import AdHocJobDialog from './Dialogs/AdHocJobDialog';
import { useJobs } from '../hooks/useJobs';
import { useValves } from '../hooks/useValves';
import { SiteContext } from './Contexts/SiteContext';
import NotifierContext from './Contexts/NotificationContext';
import { usePrivileges } from '../hooks/usePrivileges';
import { Link } from 'react-router-dom';

function Jobs() {

    const { contributor, admin } = usePrivileges();
    const { selectedSite } = useContext(SiteContext);
    const { jobs, setJobs, loading, error } = useJobs(selectedSite);
    const { valves, loading: loadingValves } = useValves(selectedSite);
    const [showAdHocJobDialog, setShowAdHocJobDialog] = useState(false);

    // Ref if required for SignalR to have access to the jobs state
    const jobsRef = useRef(jobs);
    useEffect(() => {
        jobsRef.current = jobs;
    }, [jobs]);

    const openAdHocJobDialog = () => {
        setShowAdHocJobDialog(true);
    }

    const closeAdHocJobDialog = () => {
        setShowAdHocJobDialog(false);
    }

    const onJobUpdate = (data) => {

        var jobUpdate = data.detail.data;
        if (jobUpdate.State === "New" || jobUpdate.action === "NoActionRequired") {
            return;
        }
        const jobListWithUpdatedJob = jobsRef.current.map(x => x.id === jobUpdate.id ? { ...x, ...jobUpdate } : x)
        setJobs(jobListWithUpdatedJob);
    }

    const onNewJob = (data) => {
        var job = data.detail.data;
        if (job.State === "New" || job.action === "NoActionRequired") {
            return;
        }

        const newJobList = [...jobsRef.current];
        newJobList.push(job)
        setJobs(newJobList);
    }

    var notifier = useContext(NotifierContext);

    // Get jobs and set signalR updates
    useEffect(() => {
        notifier.on("jobUpdate", onJobUpdate);
        notifier.on("newJob", onNewJob);

        return function cleanup() {
            notifier.off("jobUpdate", onJobUpdate);
            notifier.off("newJob", onNewJob);
        };
    }, [notifier]);

    // When a job is updated, update the selected job and the table
    const updateJob = (job) => {
        if (job) {
            const jobListWithUpdatedJob = jobs.map(x => x.id === job.id ? { ...x, ...job } : x);
            setJobs(jobListWithUpdatedJob);
        }
    }

    return (
        <Container fluid="xl">
            <div className="d-flex justify-content-end align-items-center mb-3">
                {contributor &&
                    <Button variant="primary" onClick={openAdHocJobDialog} disabled={loadingValves}>Ad hoc Change</Button>
                }
                {admin &&
                    <Link className="ms-2" to="/job-history">Job History</Link>
                }
            </div>
            <JobsTable jobs={jobs} updateJob={updateJob} loading={loading} error={error} valves={valves} loadingValves={loadingValves} />
            <AdHocJobDialog show={showAdHocJobDialog} closeDialog={closeAdHocJobDialog} jobs={jobs} setJobs={setJobs} valves={valves} loadingValves={loadingValves} selectedSite={selectedSite} />
        </Container>
    );
}

export default Jobs;
