import React, { useState, useEffect } from 'react';
import { RequiredDialogSelect, DialogSelect, DialogInput, handleInputChange } from '../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { useCommonConfigFunctions } from '../../../hooks/useCommonConfigFunctions';

function ConfigurationDataEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {

    const [fieldErrors, setFieldErrors] = useState({});

    const { getAllFunction: getSites, loading: loadingSites } = useCommonConfigFunctions("sites");
    const { getAllFunction: getConfigurationDataProperties, loading: loadingConfigDataProperties } = useCommonConfigFunctions("configurationDataProperties", "configuration data");

    const [sites, setSites] = useState();
    const [configDataProperties, setConfigDataProperties] = useState();

    useEffect(() => {
        getSites().then(sites => setSites(sites));
        getConfigurationDataProperties().then(configDataProperties => setConfigDataProperties(configDataProperties));
    }, [])

    const { createFunction, updateFunction, deleteFunction } = useCommonConfigFunctions("configurationData", "configuration data");

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    const createOrUpdate = (object) => {
        return object.id ? updateFunction(object) : createFunction(object);
    }

    return (
        <CommonConfigDialog title={"Configuration Data - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={createOrUpdate} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} canDelete={true} deleteFunction={deleteFunction}>
            <RequiredDialogSelect controlId="formConfigurationDataProperty" title="Property" name="configurationDataPropertyId" value={dialogData.configurationDataPropertyId} error={fieldErrors.configurationDataPropertyId} onChange={onChange}>
                <option value="" key={0}>Select...</option>
                {configDataProperties && configDataProperties.map((prop) => <option value={prop.id} key={prop.id}>{prop.name}</option>)}
            </RequiredDialogSelect>
            <DialogInput controlId="formConfigurationDataValue" title="Value" placeholder="Value" name="value" value={dialogData.value} error={fieldErrors.value} onChange={onChange} />
            <DialogSelect controlId="formConfigurationDataSite" title="Site" name="siteId" value={dialogData.siteId} error={fieldErrors.siteId} onChange={onChange}>
                <option value="" key={0}>Select...</option>
                {sites && sites.map((site) => <option value={site.id} key={site.id}>{site.name}</option>)}
            </DialogSelect>
        </CommonConfigDialog>
    );
}

export default ConfigurationDataEditDialog;