import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import Sites from './Configuration/Sites';
import Areas from './Configuration/Areas';
import ConfigurationDataProperties from './Configuration/ConfigurationDataProperties';
import ConfigurationData from './Configuration/ConfigurationData';

function Configuration() {


    return (
        <Container fluid>
            <h3 className="ms-2">Configuration</h3>
            <Tabs defaultActiveKey="configurationData" id="settings-tabs" className="tabs mt-3 mb-3" mountOnEnter={true} justify>
                <Tab eventKey="sites" title="Sites" >
                    <Sites />
                </Tab>
                <Tab eventKey="areas" title="Areas" >
                    <Areas />
                </Tab>
                <Tab eventKey="configurationDataProperties" title="Configuration Data Properties" >
                    <ConfigurationDataProperties />
                </Tab>
                <Tab eventKey="configurationData" title="Configuration Data" >
                    <ConfigurationData />
                </Tab>
            </Tabs>
        </Container >
    );
}

export default Configuration;